var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('ValidationObserver',{ref:"form",staticClass:"displayTemplate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"8","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autofocus":"","id":"primero","outlined":"","label":"Nombre","error-messages":errors},model:{value:(_vm.horario.nombre),callback:function ($$v) {_vm.$set(_vm.horario, "nombre", $$v)},expression:"horario.nombre"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Minutos tolerancia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],attrs:{"outlined":"","label":"Minutos tolerancia","error-messages":errors},model:{value:(_vm.horario.minutos_tolerancia),callback:function ($$v) {_vm.$set(_vm.horario, "minutos_tolerancia", $$v)},expression:"horario.minutos_tolerancia"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Descripción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Descripción","error-messages":errors},model:{value:(_vm.horario.descripcion),callback:function ($$v) {_vm.$set(_vm.horario, "descripcion", $$v)},expression:"horario.descripcion"}})]}}],null,true)})],1),[_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Tipo jornada","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.jornadas,"item-text":"descripcion","item-value":"id","label":"Tipo jornada","persistent-hint":"","error-messages":errors},model:{value:(_vm.horario.jornada_id),callback:function ($$v) {_vm.$set(_vm.horario, "jornada_id", $$v)},expression:"horario.jornada_id"}})]}}],null,true)})],1)],_c('v-col',{staticClass:"text-center ma-0 pa-0",attrs:{"xs":"12","sm":"12","md":"12"}},[_c('h4',{staticClass:"selDias"},[_vm._v("Selecciona los días")])]),_c('div',{staticClass:"centrar"},_vm._l((_vm.semanaHorarios),function(d,index){return _c('v-checkbox',{key:index,staticClass:"shrink mr-4 mt-0 dias",attrs:{"hide-details":"","label":d.dia,"value":d.dia,"disabled":d.desabilitar},model:{value:(_vm.dias_seleccionados),callback:function ($$v) {_vm.dias_seleccionados=$$v},expression:"dias_seleccionados"}})}),1),_c('v-layout',{staticClass:"centrar mb-5",attrs:{"row":""}},[(!_vm.navegador)?_c('v-col',{attrs:{"xs":"12","sm":"5","md":"5"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Hora entrada","type":"time","id":"hora_entrada"},model:{value:(_vm.hora_entrada),callback:function ($$v) {_vm.hora_entrada=$$v},expression:"hora_entrada"}})],1):_c('v-col',{attrs:{"xs":"12","sm":"5","md":"5"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":10,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hora entrada","readonly":"","outlined":""},model:{value:(_vm.hora_entrada),callback:function ($$v) {_vm.hora_entrada=$$v},expression:"hora_entrada"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[(_vm.menu1)?_c('v-time-picker',{attrs:{"full-widthformat":"24hr","scrollable":"","ampm-in-title":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.hora_entrada)}},model:{value:(_vm.hora_entrada),callback:function ($$v) {_vm.hora_entrada=$$v},expression:"hora_entrada"}}):_vm._e()],1)],1),(!_vm.navegador)?_c('v-col',{attrs:{"xs":"12","sm":"5","md":"5"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Hora salida","type":"time","id":"hora_salida"},model:{value:(_vm.hora_salida),callback:function ($$v) {_vm.hora_salida=$$v},expression:"hora_salida"}})],1):_c('v-col',{attrs:{"xs":"12","sm":"5","md":"5"}},[_c('v-menu',{ref:"menuFin",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hora salida","readonly":"","outlined":""},model:{value:(_vm.hora_salida),callback:function ($$v) {_vm.hora_salida=$$v},expression:"hora_salida"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"full-widthformat":"24hr","scrollable":"","ampm-in-title":""},on:{"click:minute":function($event){return _vm.$refs.menuFin.save(_vm.hora_salida)}},model:{value:(_vm.hora_salida),callback:function ($$v) {_vm.hora_salida=$$v},expression:"hora_salida"}}):_vm._e()],1)],1),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"#1E2245","elevation":"0","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.agregarHorario()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("add")])],1)],1),_vm._l((_vm.horario.detalles_horario),function(i,index){return [_c('v-col',{key:index,attrs:{"col":"12","xs":"12","md":"4"}},[_c('v-text-field',{staticClass:"detalle",attrs:{"id":"file_input_file","label":"Dia","disabled":"","type":"text","value":i.dia}})],1),_c('v-col',{key:index,attrs:{"xs":"12","sm":"3","md":"3"}},[_c('v-text-field',{staticClass:"detalle",attrs:{"label":"entrada","type":"text","value":i.hora_entrada,"disabled":""}})],1),_c('v-col',{key:index,attrs:{"xs":"12","sm":"3","md":"3"}},[_c('v-text-field',{staticClass:"detalle",attrs:{"label":"salida","type":"text","value":i.hora_salida,"disabled":""}})],1),_c('v-btn',_vm._g({key:index,staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.eliminaHorario2(index, i.id)}}},_vm.on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotonesDelete"},[_vm._v("delete")])],1)]})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }