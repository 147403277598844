var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('ValidationObserver',{ref:"form",staticClass:"displayTemplate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"8","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Nombre de la empresa","error-messages":errors,"required":"","id":"txtNombre"},model:{value:(_vm.empresa.nombre),callback:function ($$v) {_vm.$set(_vm.empresa, "nombre", $$v)},expression:"empresa.nombre"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Clave","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Clave","error-messages":errors,"required":"","id":"txtClave"},model:{value:(_vm.empresa.clave),callback:function ($$v) {_vm.$set(_vm.empresa, "clave", $$v)},expression:"empresa.clave"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Razón Social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Razón Social","error-messages":errors,"required":"","id":"txtRS"},model:{value:(_vm.empresa.razon_social),callback:function ($$v) {_vm.$set(_vm.empresa, "razon_social", $$v)},expression:"empresa.razon_social"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"RFC","rules":"required|rfc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"RFC","error-messages":errors,"required":"","id":"txtRFC"},model:{value:(_vm.empresa.rfc),callback:function ($$v) {_vm.$set(_vm.empresa, "rfc", $$v)},expression:"empresa.rfc"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"URL de Servidor","rules":"https_puerto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"URL de servidor","error-messages":errors,"required":"","id":"txtURL"},model:{value:(_vm.empresa.url_server_asp),callback:function ($$v) {_vm.$set(_vm.empresa, "url_server_asp", $$v)},expression:"empresa.url_server_asp"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Dirección","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Dirección","error-messages":errors,"required":"","id":"txtDireccion"},model:{value:(_vm.empresa.direccion),callback:function ($$v) {_vm.$set(_vm.empresa, "direccion", $$v)},expression:"empresa.direccion"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"CP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Código postal","persistent-hint":"","required":"","error-messages":errors,"id":"txtCP"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.busca_codigos()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.busca_codigos()}},model:{value:(_vm.busca_cp),callback:function ($$v) {_vm.busca_cp=$$v},expression:"busca_cp"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Estado","disabled":"","id":"txtEstado"},model:{value:(_vm.estado),callback:function ($$v) {_vm.estado=$$v},expression:"estado"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Municipio","disabled":"","id":"txtMunicipio"},model:{value:(_vm.municipio),callback:function ($$v) {_vm.municipio=$$v},expression:"municipio"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Colonia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Colonia","items":_vm.colonias,"item-text":"nombre","item-value":"id","persistent-hint":"","required":"","error-messages":errors,"id":"txtColonia"},model:{value:(_vm.empresa.colonia_id),callback:function ($$v) {_vm.$set(_vm.empresa, "colonia_id", $$v)},expression:"empresa.colonia_id"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Telefono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Teléfono","error-messages":errors,"required":"","id":"txtTelefono"},model:{value:(_vm.empresa.telefono_contacto),callback:function ($$v) {_vm.$set(_vm.empresa, "telefono_contacto", $$v)},expression:"empresa.telefono_contacto"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Contacto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Contacto","error-messages":errors,"required":"","id":"txtContacto"},model:{value:(_vm.empresa.nombre_contacto),callback:function ($$v) {_vm.$set(_vm.empresa, "nombre_contacto", $$v)},expression:"empresa.nombre_contacto"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Correo Contacto","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Correo Contacto","error-messages":errors,"required":"","id":"txtCorreoContacto"},model:{value:(_vm.empresa.correo_contacto),callback:function ($$v) {_vm.$set(_vm.empresa, "correo_contacto", $$v)},expression:"empresa.correo_contacto"}})]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }