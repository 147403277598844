var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('ValidationObserver',{ref:"form",staticClass:"displayTemplate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Nombre de puesto","error-messages":errors,"required":""},model:{value:(_vm.puesto.nombre),callback:function ($$v) {_vm.$set(_vm.puesto, "nombre", $$v)},expression:"puesto.nombre"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Funciones","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Funciones","error-messages":errors,"required":""},model:{value:(_vm.puesto.funciones),callback:function ($$v) {_vm.$set(_vm.puesto, "funciones", $$v)},expression:"puesto.funciones"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"5"}},[_c('VuetifyMoneyValidate',{attrs:{"rules":"required","outlined":"","label":"Sueldo mínimo sugerido","prefix":"$","options":_vm.options},model:{value:(_vm.puesto.sueldo),callback:function ($$v) {_vm.$set(_vm.puesto, "sueldo", $$v)},expression:"puesto.sueldo"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"4"}},[_c('VuetifyMoneyValidate',{attrs:{"rules":"required","outlined":"","label":"Sueldo máximo","prefix":"$","options":_vm.options},model:{value:(_vm.puesto.sueldo_maximo),callback:function ($$v) {_vm.$set(_vm.puesto, "sueldo_maximo", $$v)},expression:"puesto.sueldo_maximo"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Plazas","rules":"required|numeros_positivos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"outlined":"","label":"Plazas","error-messages":errors,"required":""},model:{value:(_vm.puesto.plazas),callback:function ($$v) {_vm.$set(_vm.puesto, "plazas", $$v)},expression:"puesto.plazas"}})]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }