import gql from 'graphql-tag';

const empleadosTableQuery = gql`
    query empleados($whereConditions:EmpleadosWhereWhereConditions, $numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        empleados(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                nombre_completo,
                correo,
                clave,
                foto,
                nombre,
                apellido_paterno,
                asimilado,
                ranking{
                    valor
                },
                getRanking,
                getClave,
                tipoRegimen{
                    clave,
                    descripcion
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`
const getEmpleadosQuery = gql`
    query empleados($whereConditions:EmpleadosWhereWhereConditions, $numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        empleados(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                puesto_id
                nombre_completo,
                nombre,
                apellido_paterno,
                apellido_materno,
                foto,
                puesto{
                    nombre
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

/* agregar regimen_fiscal_id para timbre 4.0 */
const getEmpleadoQuery = gql `
    query empleado($id: ID!) {
        empleado(id: $id) {
            id,
            estado_id,
            horario_id,
            cliente_id,
            empresa_id,
            sucursal_id,
            departamento_id,
            nombre,
            apellido_paterno,
            apellido_materno,
            fecha_nacimiento,
            sexo,
            direccion,
            telefono,
            correo,
            fecha_ingreso,
            fecha_baja,
            baja,
            fecha_cobro_finiquito,
            dias_vacaciones,
            vacaciones_fiscales,
            clave,
            estado_civil_id,
            clave_interna,
            foto,
            tiene_hijos,
            rfc,
            curp,
            tipo_contrato_id,
            segundo_contrato_id,
            dias_contrato,
            salario_diario,
            salario_diario_fiscal,
            salario_base_cotizacion,
            registro_patronal_id,
            imss,
            fecha_alta_imss,
            tipo_sangre,
            fecha_inicio_descuento,
            tipo_factor,
            factor_descuento,
            fecha_planta,
            cuenta_fonacot,
            cuenta_infonavit,
            afore,
            nombre_contacto,
            telefono_contacto,
            observaciones,
            nivel_estudios_id,
            puesto_id,
            municipio_id,
            cp_id,
            colonia_id,
            prestacion_id,
            tipo_nomina_id,
            entidad_cfdi,
            brigada_proteccion_civil_id,
            nacionalidad,
            lugar_nacimiento,
            celular,
            conocimientos_extras,
            direccion_laboral,
            observacion_salud,
            tipo_regimen_sat_id,
            regimen_fiscal_id
            asimilado,
            tipo_pago,
            codigoPostalTimbre{
                valor
            }
            direccion_contrato,
            empleado_tipo_id,
            cuentasBancarias {
                id,
                activo,
                cuenta_bancaria,
                clabe_bancaria,
                banco_id,
                banco {
                    id,
                    nombre
                }
            }
            documentos {
                id
                nombre
                url
                descripcion
                tipo {
                    id
                    nombre
                }
            }
            ranking{
                valor,
                clima_laboral,
                proyectos_mejoras
            },
            cursosCapacitaciones{
                id
                empleado_id,
                evaluacion,
                observaciones,
                curso_capacitacion_id,
                cursoCapacitacion{
                    nombre
                }
            },
            codigoPostal{
                nombre
            }
            estado{
                nombre
            }
            municipio{
                nombre
            }
            cliente{
                nombre
            }
            empresa{
                nombre
            }
            sucursal{
                nombre
            }
            departamento{
                nombre
            }
            puesto{
                nombre
            }
            colonia{
                nombre
            }
            estudios{
                nombre
            }
            contratoFormato{
                nombre
            }
            brigada{
                nombre
            }
            estadoCivil{
                nombre
            }
            horario{
                nombre
            }
            registroPatronal{
                nombre
            }
            tipoNomina{
                descripcion,
                precalculo_variables
            }
            tipoRegimen{
                clave,
                descripcion
            }
            regimenFiscal{
                clave,
                descripcion
            }
            empleadoTipo{
				descripcion
			}
            empleadoIMSS{
                IMSS_tipo_trabajador_id,
                IMSS_tipo_salario_id,
                IMSS_semana_jornada_id
            },
            salarioMinimo{
                nuevo_sbc_fijo,
                nuevo_sbc_variable,
                fecha_de_aplicacion,
                nuevo_sbc,
                nuevo_sueldo
            },
            empleado_categoria_id,
            empleadoCategoria{
                id,
                nombre
            }
            centroCostos{
                id,
                descripcion
            }
        }
    }
`

const queries = { empleadosTableQuery, getEmpleadoQuery, getEmpleadosQuery };

export default queries;
