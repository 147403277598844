var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('ValidationObserver',{ref:"form",staticClass:"displayTemplate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","autofocus":"","id":"primero","label":"Nombre","error-messages":errors,"required":""},model:{value:(_vm.departamento.nombre),callback:function ($$v) {_vm.$set(_vm.departamento, "nombre", $$v)},expression:"departamento.nombre"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Encargado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Encargado","error-messages":errors,"required":""},model:{value:(_vm.departamento.encargado),callback:function ($$v) {_vm.$set(_vm.departamento, "encargado", $$v)},expression:"departamento.encargado"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"textoCorreos"},[_vm._v("Ingresa los correos de personas que darán seguimiento a las solicitudes del departamento:")]),_c('ValidationProvider',{attrs:{"name":"Emails","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","label":"Emails","error-messages":errors,"required":"","rows":"3","persistent-placeholder":true,"placeholder":"jackson.graham@example.com, debra.hold@example.com, michelle.rivera@example.com, geogia.young@example.com"},model:{value:(_vm.departamento.correo),callback:function ($$v) {_vm.$set(_vm.departamento, "correo", $$v)},expression:"departamento.correo"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":"Descripción"},model:{value:(_vm.departamento.descripcion),callback:function ($$v) {_vm.$set(_vm.departamento, "descripcion", $$v)},expression:"departamento.descripcion"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }